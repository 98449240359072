import Image from 'next/image';

export function IconSearchBlue() {
    return (
        <Image
            src={'/images/icon-search-blue.webp'}
            alt="Icon"
            width={15}
            height={15}
            priority
            loading="eager"
        />
    );
}
