import { GetStaticProps } from 'next';
import Index, { Props } from '@/components/pages/Index';
import { loadNewsFromMicroCmsForLP } from '@/modules/news';
import { loadColumnsFromMicroCmsForLP } from '@/modules/column';
import { getSuperUpstreamExcitingProjects } from '@/modules/project';
import { getAtOfficeFrequencies } from '@/modules/master';

export const getStaticProps: GetStaticProps<Props> = async () => {
    // microCMS:news取得
    const newsContent = await loadNewsFromMicroCmsForLP();
    // microCMS:column取得
    const columnContent = await loadColumnsFromMicroCmsForLP();
    // 超上流の刺激的な案件取得
    const superUpstreamExcitingProjects =
        await getSuperUpstreamExcitingProjects();
    // 出社頻度マスタの取得
    const [atOfficeFrequencies] = await Promise.all([getAtOfficeFrequencies()]);
    return {
        props: {
            newsContentList: newsContent,
            columnContentList: columnContent,
            superUpstreamExcitingProjects,
            atOfficeFrequencies
        },
        revalidate: 180
    };
};

export default Index;
