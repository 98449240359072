import { IconSearchGray } from '@/components/Icon/IconSearchGray';
import { Master } from '@/types/master';
import { ProjectsFilterFormForFV } from '@/types/project';
import { lowerLimitList } from '@/constants/projectSearch';
import { TextInput } from '@/components/Input/TextInput';
import { Select } from '@/components/Input/SelectInput';
import { useMedia } from '@/hooks/useMedia';
import { IconSearchBlue } from '@/components/Icon/IconSearchBlue';

export type ProjectSearchProps = {
    values: ProjectsFilterFormForFV;
    atOfficeFrequencies: Master[];
    onChangeValue: (
        field: keyof ProjectsFilterFormForFV,
        value: string | number
    ) => void;
    onFilter: () => void;
};

export const ProjectSearch = ({
    values,
    atOfficeFrequencies,
    onChangeValue,
    onFilter
}: ProjectSearchProps) => {
    const isPC = useMedia();

    const handleOnChangeValue = (
        field: keyof ProjectsFilterFormForFV,
        value: string
    ) => {
        onChangeValue(field, value);
    };
    const handleOnChangeNumberValue = (
        field: 'lowerLimitMonthlyRate',
        value: string
    ) => {
        onChangeValue(field, parseInt(value));
    };

    return (
        <div className="flex flex-col md:flex-row justify-center bg-[#182744] h-[300px] md:h-[124px] p-4">
            <div className="flex flex-col md:flex-row items-center justify-center md:space-x-4 xl:space-x-8 md:space-y-0 space-y-4 m-4">
                {/* キーワード検索 */}
                <TextInput
                    value={values.text}
                    onChangeValue={(value) =>
                        handleOnChangeValue('text', value)
                    }
                    placeholder="フリーワード（職種、ツールなど）"
                    leftSection={<IconSearchGray />}
                    className="w-full md:w-auto"
                    maw={366}
                    mx={'auto'}
                    size={isPC ? 'lg' : 'md'}
                />
                {/* 出社頻度 */}
                <Select
                    value={values.atOfficeFrequency}
                    data={atOfficeFrequencies}
                    onChangeValue={(value) =>
                        handleOnChangeValue('atOfficeFrequency', value)
                    }
                    placeholder="出社頻度"
                    variant={'default'}
                    maw={366}
                    className="w-full md:w-auto flex justify-center"
                    w={'100%'}
                    pl={0}
                    bd={'none'}
                    size={isPC ? 'lg' : 'md'}
                />
                {/* 単価 */}
                <Select
                    value={
                        values.lowerLimitMonthlyRate
                            ? values.lowerLimitMonthlyRate?.toString()
                            : ''
                    }
                    data={lowerLimitList}
                    onChangeValue={(value) =>
                        handleOnChangeNumberValue(
                            'lowerLimitMonthlyRate',
                            value
                        )
                    }
                    placeholder="単価"
                    variant={'default'}
                    maw={366}
                    className="w-full md:w-auto flex justify-center border-none"
                    w={'100%'}
                    pl={0}
                    bd={'none'}
                    size={isPC ? 'lg' : 'md'}
                />
            </div>
            <button
                onClick={onFilter}
                className="flex items-center justify-center rounded-[4px] max-w-[366px] md:max-w-[260px] w-full min-w-[100px] h-[60px] leading-[26px] bg-[#FFEC5D] m-auto md:mx-4 font-bold hover:opacity-70 mt-4 md:mt-auto"
            >
                <p className="font-hiragino-sans text-[#1D55AE] text-center text-[18px] mr-[6px]">
                    案件を検索
                </p>
                <IconSearchBlue />
            </button>
        </div>
    );
};
